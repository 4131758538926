import React from "react";
import { TentangKami, VisiMisi,} from "../../components";

const Tentang = () => {
  return (
    <div className="">
      <TentangKami />
      <VisiMisi />
    </div>
  );
};

export default Tentang;