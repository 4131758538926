import Footer from './Footer';
import Loader from './Loader';
import Navbar from './Navbar';
import NotFound from './NotFound';
import TentangKami from './TentangKami';
import VisiMisi from './VisiMisi';

export {
    Footer,Loader,Navbar,
    NotFound, TentangKami,VisiMisi
};