// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impactfull-card {
    border: 1px solid transparent;
    border-image: linear-gradient(to top, purple 0%, purple 100%);
    border-image-slice: 1;
    background-color: transparent;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Blur/Blur.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,6DAA6D;IAC7D,qBAAqB;IACrB,6BAA6B;IAC7B,mCAA2B;YAA3B,2BAA2B;AAC/B","sourcesContent":[".impactfull-card {\n    border: 1px solid transparent;\n    border-image: linear-gradient(to top, purple 0%, purple 100%);\n    border-image-slice: 1;\n    background-color: transparent;\n    backdrop-filter: blur(10px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
