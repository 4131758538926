// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-line {
    height: 8px;
    width: 8px;
    margin: 0 10px;
    background-color: purple;
    transform: rotate(45deg);
}

.middle-line {
    width: 180px;
    height: 4px;
    background-color: purple;
    border-radius: 4px;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/BottomLine/BottomLine.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,UAAU;IACV,cAAc;IACd,wBAAwB;IACxB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".side-line {\n    height: 8px;\n    width: 8px;\n    margin: 0 10px;\n    background-color: purple;\n    transform: rotate(45deg);\n}\n\n.middle-line {\n    width: 180px;\n    height: 4px;\n    background-color: purple;\n    border-radius: 4px;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
