// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bghome.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-lobster {
  font-family: "Lobster", cursive;
  letter-spacing: 2px;
}

.bghome {
    background-image:linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.parent {
  padding-right: 8%;
  padding-left: 8%;
}

@media only screen and (max-width: 600px) {
  .parent {
    padding-right: 5%;
    padding-left: 5%;
  }

  .text-button-mobile {
    font-size: 10px;
  }
}

.visible {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 40;
  height: 4rem;
  transition: 0.3s linear;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.nav-hidden {
  top: -80px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/shared/Shared.css"],"names":[],"mappings":"AAEA;EACE,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;IACI,mHAAiH;AACrH;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,WAAW;EACX,eAAe;EACf,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lobster&display=swap\");\n\n.font-lobster {\n  font-family: \"Lobster\", cursive;\n  letter-spacing: 2px;\n}\n\n.bghome {\n    background-image:linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(\"../../assets/images/bghome.png\");\n}\n\n.parent {\n  padding-right: 8%;\n  padding-left: 8%;\n}\n\n@media only screen and (max-width: 600px) {\n  .parent {\n    padding-right: 5%;\n    padding-left: 5%;\n  }\n\n  .text-button-mobile {\n    font-size: 10px;\n  }\n}\n\n.visible {\n  width: 100%;\n  position: fixed;\n  top: 0px;\n  z-index: 40;\n  height: 4rem;\n  transition: 0.3s linear;\n  display: flex;\n  justify-content: stretch;\n  align-items: center;\n}\n\n.nav-hidden {\n  top: -80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
